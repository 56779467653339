/**
 * File which-transition-event.js.
 *
 * Provide a crossbrowser way to determine which
 * transitionend event is supported by the current browser.
 *
 * Author: Diego Versiani
 * Contact: http://diegoversiani.me
 * 
 * Based on the work of:
 * Jonathan Suh - https://jonsuh.com/blog/detect-the-end-of-css-animations-and-transitions-with-javascript/
 * David Walsh - https://davidwalsh.name/css-animation-callback
 */
( function() {

  /* From Modernizr */
  function whichTransitionEvent() {
    var t;
    var el = document.createElement('fakeelement');
    var transitions = {
      'transition':'transitionend',
      'OTransition':'oTransitionEnd',
      'MozTransition':'transitionend',
      'WebkitTransition':'webkitTransitionEnd'
    }

    for(t in transitions){
      if( el.style[t] !== undefined ){
        return transitions[t];
      }
    }
  };



  window.whichTransitionEvent = whichTransitionEvent;

})();
