/**
 * File getQueryString.js.
 *
 * Get values from the queryString.
 *
 * Author: Diego Versiani
 * Contact: https://diegoversiani.me/
 * 
 * Based on the work of:
 * Chris Ferdinandi - https://gomakethings.com/how-to-get-the-value-of-a-querystring-with-native-javascript/
 */
( function() {

  /**
   * Get the value of a querystring
   * @param  {String} field The field to get the value of
   * @param  {String} url   The URL to get the value from (optional)
   * @return {String}       The field value
   */
  var getQueryString = function ( field, url ) {
    var href = url ? url : window.location.href;
    var reg = new RegExp( '[?&]' + field + '=([^&#]*)', 'i' );
    var string = reg.exec(href);
    return string ? string[1] : null;
  };



  window.getQueryString = getQueryString;

})();
