/**
 * File number-input.js.
 *
 * Add plus/minus buttons to input type number.
 *
 * Author: Diego Versiani
 * Contact: https://diegoversiani.me
 *
 * Depends on:
 * - polyfill-classList.js
 * - polyfill-closest.js
 * - polyfill-CustomEvents.js
 * - debounce.js // wait some time until user stops interacting to execute a function
 */
(function (root, factory) {
  if ( typeof define === 'function' && define.amd ) {
    define([], factory(root));
  } else if ( typeof exports === 'object' ) {
    module.exports = factory(root);
  } else {
    root.inputNumbers = factory(root);
  }
})(typeof global !== 'undefined' ? global : this.window || this.global, function (root) {

  'use strict';

  //
  // Variables
  //
  var window = root; // Map window to root to avoid confusion
  var publicMethods = {}; // Placeholder for public methods

  // Default settings
  var defaults = {
    container: 'body',
    inputSelector: 'input[type="number"]',
    inputClass: 'buttons-added',
    minusButtonTemplate: '<button type="button" class="number-spin-button minus" title="Decrease">-</button>',
    plusButtonTemplate: '<button type="button" class="number-spin-button plus" title="Increase">+</button>',
    onChange: null,
  };



  //
  // Private Methods
  //
  
  /**
   * Merge two or more objects. Returns a new object.
   * @private
   * @param {Boolean}  deep     If true, do a deep (or recursive) merge [optional]
   * @param {Object}   objects  The objects to merge together
   * @returns {Object}          Merged values of defaults and options
   */
  var extend = function () {
    // Variables
    var extended = {};
    var deep = false;
    var i = 0;
    var length = arguments.length;

    // Check if a deep merge
    if ( Object.prototype.toString.call( arguments[0] ) === '[object Boolean]' ) {
      deep = arguments[0];
      i++;
    }

    // Merge the object into the extended object
    var merge = function (obj) {
      for ( var prop in obj ) {
        if ( Object.prototype.hasOwnProperty.call( obj, prop ) ) {
          // If deep merge and property is an object, merge properties
          if ( deep && Object.prototype.toString.call(obj[prop]) === '[object Object]' ) {
            extended[prop] = extend( true, extended[prop], obj[prop] );
          } else {
            extended[prop] = obj[prop];
          }
        }
      }
    };

    // Loop through each object and conduct a merge
    for ( ; i < length; i++ ) {
      var obj = arguments[i];
      merge(obj);
    }

    return extended;
  };



  /**
   * Add spin buttons
   */
  var addButtons = function( options, input ) {
    // Create temporary element to create buttons from string template
    var tempDiv = document.createElement('div');

    // Create minus button from template and insert it before input
    tempDiv.innerHTML = options.minusButtonTemplate;
    tempDiv.firstChild.setAttribute( 'data-number-spinner-button', 'minus' );
    input.parentNode.insertBefore( tempDiv.firstChild, input );

    // Create plus button from template and insert it after input
    tempDiv.innerHTML = options.plusButtonTemplate;
    tempDiv.firstChild.setAttribute( 'data-number-spinner-button', 'plus' );
    input.parentNode.insertBefore( tempDiv.firstChild, input.nextSibling );

    // Add class to input element
    input.classList.add( options.inputClass );

    // Remove tempDiv element from the DOM
    if ( tempDiv.remove ) { tempDiv.remove(); }
  };



  /**
   * Handle button click
   */
  var handleCapturedClick = function( e ) {
    // Get target button
    var target = e.target.closest( '[data-number-spinner-button]' );

    // Exit if number spinner button not clicked
    if ( !target ) { return; }

    // Get target input
    var targetInput = target.nextElementSibling;
    if ( !targetInput ) { targetInput = target.previousElementSibling; }

    // Get number field step and invert signal if minus button
    var step = targetInput.getAttribute( 'step' );
    step = step ? parseInt( step ) : 1;
    step = target.getAttribute( 'data-number-spinner-button' ) == 'minus' ? -1 * step : step;

    // Get min and max values
    var min = targetInput.getAttribute( 'min' );
    min = min != '' ? parseInt( min ) : null;
    min = min == NaN ? null : min;
    var max = targetInput.getAttribute( 'max' );
    max = max != '' ? parseInt( max ) : null;
    max = max == NaN ? null : max;

    // Get new value
    var value = parseInt( targetInput.value || 0 );
    value = value + step;

    // Handle min and max values
    if ( min != null && value <= min ) { value = min; }
    if ( max != null && value >= max ) { value = max; }

    // Set new value
    targetInput.value = value;
    targetInput.focus();
    
    // Dispatch change event.
    var changeEvent = new CustomEvent( 'change', { detail: {}, bubbles: false } );
    targetInput.dispatchEvent( changeEvent );

    // Lose focus from button
    e.target.blur();
  };



  //
  // Public Methods
  //

  /**
   * Initialize plugin
   */
  publicMethods.init = function( options ) {
    // Merge user options with defaults
    options = extend( defaults, options || {} );

    // Bail early if there is no container
    if ( !options.container || options.container == '' )  { return; }

    var containerElement = document.querySelector( options.container );

    // Bail early if there is no containerElement
    if ( !containerElement ) { return; }

    var inputElements = containerElement.querySelectorAll( options.inputSelector );

    for (var i = 0; i < inputElements.length; i++) {
      // Check if already initialized
      if ( !inputElements[i].classList.contains( options.inputClass ) ) {
        // Add buttons
        addButtons( options, inputElements[i] );
      }

      // Add event handlers
      if ( options.onChange && typeof options.onChange === "function" ) {
        // Try remove event listener to prevent duplicate, then add.
        inputElements[i].removeEventListener( 'change', debounce( options.onChange, 200 ) );
        inputElements[i].addEventListener( 'change', debounce( options.onChange, 200 ) );
      }
    }

    // Add capture click event listener
    document.addEventListener( 'click', handleCapturedClick, true );
  };



  //
  // Public APIs
  //
  return publicMethods;

});
