/**
 * File option-box.js.
 * 
 * Keep option-box visual state syncronized with it's related input checked state.
 */
(function(){

  'use strict';

  //
  // VARIABLES
  //
  
  var _selectedClass = 'selected',
      _optionBoxSelector = '.option-box',
      _optionSelector = 'input[type="radio"], input[type="checkbox"], .option-box input[type="checkbox"] + label, .option-box input[type="checkbox"] + span, .option-box input[type="radio"] + label, .option-box input[type="radio"] + span, input[type="checkbox"] + label.option-box, input[type="checkbox"] + span.option-box, input[type="radio"] + label.option-box, input[type="radio"] + span.option-box';


  //
  // METHODS
  //

  /**
   * Get input related to an optionBox.
   */
  var getInput = function( optionBox ) {
    var input = optionBox.previousElementSibling;
    
    if ( ! input || ( input.getAttribute('type') != 'radio' && input.getAttribute('type') != 'checkbox' ) ) {
      input = optionBox.querySelector('input[type="checkbox"], input[type="radio"]');
    }

    return input;
  };

  /**
   * Get input related to an optionBox.
   */
  var getOptionBox = function( input ) {
    var optionBox;

    // Try from closest option-box
    optionBox = input.closest( _optionBoxSelector );
    
    // Try from input sibling
    if ( ! optionBox ) {
      optionBox = input.nextElementSibling;
    }

    return optionBox;
  };



  /**
   * Set checked status of option-box.
   */
  var setCheckedStatus = function( optionBox, input ) {
    // Add or remove selected class
    if ( input.checked ) {
      optionBox.classList.add( _selectedClass );
    }
    else {
      optionBox.classList.remove( _selectedClass );
    }
  };



  /**
   * Unselect option box of the same group.
   */
  var setRelatedOptionBoxStatus = function( input ) {
    if ( input.getAttribute( 'type' ) == 'radio' ) {
      var relatedInputs = document.querySelectorAll( '[name="'+input.getAttribute( 'name' )+'"]' );
      for (var i = relatedInputs.length - 1; i >= 0; i--) {
        if ( relatedInputs[i] != input && ! relatedInputs[i].checked ) {
          var relatedOptionBox = getOptionBox( relatedInputs[i] );
          setCheckedStatus( relatedOptionBox, relatedInputs[i] );
        }
      }
    }
  };



  /**
   * Handle captured `change` event and route to the appropriate function.
   */
  var handleChange = function( e ) {
    if ( e.target.matches( _optionSelector ) ) {
         
      // Get option-box from event target
      var optionBox = getOptionBox( e.target );
      
      if ( optionBox ) {
        // Get related input
        var input = getInput( optionBox );

        // Bail if input not found
        if ( ! input ) { return; }

        // Hacky: Need to run 'checked' test after click event ends
        // and input status is set by the browser
        setTimeout( function() {
          setCheckedStatus( optionBox, input );
          setRelatedOptionBoxStatus( input );
        }, 10 );
      }

    }
  };



  /**
   * Initialize component and set related handlers
   */
  function init() {
    // Set event handlers
    document.addEventListener( 'change', handleChange, true );
  };



  // Run initialize on pageload
  window.addEventListener( 'load', init );

})();
