/**
 * File number-input-init.js.
 *
 * Initialize number input component.
 *
 * Author: Diego Versiani
 * Contact: https://diegoversiani.me
 */

(function(){

  'use strict';

  // Run initialize on pageload
  window.addEventListener( 'DOMContentLoaded', init );

  /**
   * Initialize component and set related handlers
   */
  function init() {
    // Bail early if component not loaded
    if ( ! window.inputNumbers || ! window.lobsteranywhereSettings ) { return; }

    // Init catalog view selector component and set local variables
    inputNumbers.init({
      inputSelector: lobsteranywhereSettings.numberInput.inputSelector,
      minusButtonTemplate: lobsteranywhereSettings.numberInput.numberInputMinusButtonTemplate,
      plusButtonTemplate: lobsteranywhereSettings.numberInput.numberInputPlusButtonTemplate,
    });
  };

})();
