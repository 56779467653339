/**
 * File back-to-top.js.
 * Show the floating link when scroll down, and hide show scrolled at top.
 */
(function(){

  'use strict';

  // Run initialize on pageload
  window.addEventListener( 'load', init );



  //
  // VARIABLES
  //
  
  var _linkElement,
      _linkSelector = '.floating-to-top',
      _linkShowState = 'floating-to-top--show',
      _lastScrollPosition = 0;


  //
  // METHODS
  //

  /**
   * Initialize component and set related handlers
   */
  function init() {
    _linkElement = document.querySelector( _linkSelector );

    // Bail early if component not loaded
    if ( !_linkElement ) { return; }

    // Set event handlers
    document.addEventListener( 'click', handleCapturedClick );
    window.addEventListener( 'scroll', throttle( handleScroll, 100 ) );
  };



  /**
   * Handle document clicks and route to the appropriate function.
   */
  function handleScroll() {
    var currentScrollPosition = window.pageYOffset || document.body.scrollTop;
    var winHeight = window.innerHeight;
    var scrollPercent = currentScrollPosition / winHeight;
    var scrollPercentRounded = Math.round( scrollPercent * 100 );

    if ( scrollPercentRounded > 150 ) {
      _linkElement.classList.add( _linkShowState );
    }
    else{
      _linkElement.classList.remove( _linkShowState );
    }

  };



  /**
   * Handle document clicks and route to the appropriate function.
   */
  function handleCapturedClick( e ) {
    if ( e.target.closest( '[data-scroll-top]' ) ) {
      e.preventDefault();

      if ( window.SmoothScroll ) {
        var scroll = new SmoothScroll();
        scroll.animateScroll( 0, null, { speed: 300, easing: 'ease' } );
        scroll.destroy(); // clear memory
      }
      else {
        window.scrollTo( {
          top: 0,
          behavior: 'smooth'
        } );
      }

      // Remove show state
      _linkElement.classList.remove( _linkShowState );
    }
  };

})();
